@import '../../styles/variables.scss';

.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $basic-dark;
  z-index: 10;
  @include desktop-media(
        $background: $basic-white,
    );
}
.apeLoaderWrapper {
  position: absolute;
  left: 50%;
  top: 108px;
  transform: translate(-50%, calc(-50% + 10vw));
  @include desktop-media(
      $top: 50%,
      $transform: translate(-50%, -50%),
  );
    .blobs {
      @include filter(url("#goo"));
      position: absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
    }
    .blob {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 20vw;
      height: 20vw;
      @include border-radius(50%);
      background-color: $primary500;
      @include desktop-media(
        $width: 50px,
        $height: 50px,
      );
    }
    .blob:nth-child(1) {
      @include animation(blob-left-anim cubic-bezier(0.770, 0.000, 0.175, 1.000) 2s infinite);
    }
    .blob:nth-child(2) {
      @include animation(blob-right-anim cubic-bezier(0.770, 0.000, 0.175, 1.000) 2s infinite);
    }
  
    @include keyframes(blob-left-anim) {
      0% { @include transform(scale(1.0) translate(0, 0));}
      33% {@include transform(scale(0.55, 0.5) translate(80px, 0));}
      66% {@include transform(scale(0.8) translate(0, 0));}
      100% {@include transform(scale(1.0) translate(0, 0));}
    }
  
    @include keyframes(blob-right-anim) {
      0% {@include transform(scale(1.0) translate(0, 0));}
      33% {@include transform(scale(0.55, 0.5) translate(-80px, 0));}
      66% {@include transform(scale(0.8) translate(0, 0));}
      100% {@include transform(scale(1.0) translate(0, 0));}
    }
  
    @include keyframes(apester) {
      0% {@include transform(scale(1.0)); @include opacity(1);}
      20% {@include transform(scale(1.0)); @include opacity(0);}
      40% {@include transform(scale(0.5)); @include opacity(0);}
      66% {@include transform(scale(0.8)); @include opacity(1);}
      100% {@include transform(scale(1.0)); @include opacity(1);}
    }
  
    .apester {
      background: url("/ape-loader.svg") no-repeat center;
      position: absolute;
      height: 12vw;
      width: 12vw;
      left: calc(50% - 6vw);
      top: calc(50% - 6vw);
      @include desktop-media(
        $height: 28px,
        $width: 28px,
        $left: calc(50% - 14px),
        $top: calc(50% - 14px),
      );
      @include animation(apester cubic-bezier(0.770, 0.000, 0.175, 1.000) 2s infinite);
    }
  
  }