@import '../../styles/variables.scss';

.snackbar.apeSnackbar {
  top: 0;
  left: 0;
  right: 0;
  animation: enter 0.5s ease-in-out;
  transform: none;
  div[class*="MuiSnackbarContent-root"] {
    width: 100%;
    border-radius: 0;
    justify-content: center;
    font-family: $primaryFontFamily;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 56px;
    padding: 0;
    background: #EC9726;
    .snackbarContent {
      display: flex;
      img {
        margin-right: 8px;
      }
    }
  }
}

@keyframes enter {
  0% { top: -50px }
  50% { top: 5px }
  100% { top: 0 }
}
