@import '../../styles/variables.scss';

.header{
    background: $basic-black;
    padding: 21px 20px;
    position: fixed;
    width: 100%;
    height: 20px;
    top:0;
    z-index: 20;
    @include desktop-media(
        $background: $basic-white,
        $box-shadow: 0px 1px 0px 0px $neutral200,
    )
}

.logo{
    height: 18px;
}