@use "sass:meta";

$basic-blue: #5397FF;
$dark-blue: #4C8BEB;
$basic-white: #ffffff;
$dark-white: #ffffffcc;
$basic-black: #000000;
$basic-dark: #242424;
$basic-gray: #B4B4B4;
$light-gray: #CCCCCC;
$link-gray: #A6A6A6;
$gray-subtitle: #8B8B8B;
$back-gray: #808080;
$neutral50: #F4F4F5;
$neutral100: #E8E8EB;
$neutral200: #D2D2D7;
$neutral300: #BCBCC3;
$neutral400: #A5A5AF;
$neutral500: #8E8E9B;
$neutral600: #616173;
$neutral700: #4B4B5F;
$neutral800: #35354B;
$neutral900: #1E1E37;
$primary50: #FEF5F5;
$primary100: #F9C1C5;
$primary300: #F17077;
$primary500: #EB323C;
$primary600: #D32D36;
$secondary400: #0082FA;
$secondary500: #0075E1;

$use-case-background: #f7f7f7;
$primaryFontFamily: Roboto, sans-serif;
$secondaryFontFamily: LatoLatin, sans-serif;


$engineColors: (
  poll: #ee9802,
  countdown: #ffcd00,
  quiz: #8fd100,
  personality: #d61edd,
  video: #24dfd0,
  story: #579aff,
  article: #1bb5a9,
  gallery: #6d1edd,
  slideshow: #2762bc,
  custom: #3AB9EF
);

$templates-container-max-width-desktop: 1368px;
$templates-container-horizontal-margin-desktop: 40px;
$templates-container-horizontal-margin-tablet: 32px;
$templates-container-horizontal-margin-phone: 24px;
$templates-grid-gap: 24px;
$templates-grid-gap-tablet: 32px;
$templates-column-amount-desktop: 4;
$templates-column-amount-tablet: 2;
$templates-column-amount-phone: 1;
$template-column-proportion: 0.75;


$large-desktop-width: 1440px;
$medium-desktop-width: 1025px;
$tablet-width: 600px;

@mixin large-desktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

@mixin medium-desktop {
  @media (max-width: #{$large-desktop-width - 1px}) and (min-width: #{$medium-desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$medium-desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$medium-desktop-width - 1px}) and (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$medium-desktop-width - 1px}) {
    @content;
  }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content;
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-ms-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    }
  }

@mixin animation($stuff ) {
    -webkit-animation: $stuff;
    -moz-animation: $stuff;
    -ms-animation: $stuff;
    animation: $stuff;
  }

  @mixin opacity($value){
    $IEValue: $value*100;
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
    filter: alpha(opacity=$IEValue);
  }

  @mixin filter($value) {
    -webkit-filter: $value;
       -moz-filter: $value;
         -o-filter: $value;
            filter: $value;
  }

  @mixin border-radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      border-radius: $radius;
  }

  @mixin transform($transforms) {
      -webkit-transform: $transforms;
      -moz-transform: $transforms;
      -ms-transform: $transforms;
      transform: $transforms;
  }

  @mixin desktop-media($args...) {
    @media only screen and (min-width: 1024px){
      @each $property, $value in meta.keywords($args) {
        #{$property}: $value;
      }
    }
  }

  @mixin setGrid($columns, $columns-gap, $rows, $rows-gap, $type) {
    display: grid;
    @if ($type == 'columns') {
      grid-template-columns: $columns;
      grid-column-gap: $columns-gap;
    } @else if ($type == 'rows') {
      grid-template-rows: $rows;
      grid-row-gap: $rows-gap;
    } @else if ($type == 'both') {
      grid-template-columns: $columns;
      grid-column-gap: $columns-gap;
      grid-template-rows: $rows;
      grid-row-gap: $rows-gap;
    }
  }

  @mixin setGridAuto($size, $gap, $type) {
    display: grid !important;
    @if ($type == "columns") {
      grid-auto-columns: $size;
      grid-template-columns: $size;
      grid-column-gap: $gap;
      grid-auto-flow: column;
    } @else if ($type == "rows") {
      grid-auto-rows: $size;
      grid-template-rows: $size;
      grid-row-gap: $gap;
      grid-auto-flow: row;
    }
  }

  .demi-bold {
    font-weight: 600;
  }

  @keyframes fadeInAnimation {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


  $icomoon-font-family: "icomoon" !default;
  $icomoon-font-path: "../fonts" !default;
  
  $icon-auto-poll: unquote('"\\e925"');
  $icon-star: unquote('"\\e924"');
  $icon-user-profile: unquote('"\\e923"');
  $icon-countdown: unquote('"\\e917"');
  $icon-custom: unquote('"\\e91c"');
  $icon-gallery: unquote('"\\e91d"');
  $icon-personality: unquote('"\\e91e"');
  $icon-poll: unquote('"\\e91f"');
  $icon-quiz: unquote('"\\e920"');
  $icon-video: unquote('"\\e921"');
  $icon-story: unquote('"\\e922"');
  $icon-cross: unquote('"\\e91b"');
  $icon-union: unquote('"\\e91a"');
  $icon-arrow-left: unquote('"\\e918"');
  $icon-arrow-right: unquote('"\\e919"');
  $icon-video-no-border: unquote('"\\e90e"');
  $icon-gallery-no-border: unquote('"\\e90d"');
  $icon-personality-no-border: unquote('"\\e910"');
  $icon-arrow-next: unquote('"\\e916"');
  $icon-article: unquote('"\\e915"');
  $icon-story-no-border: unquote('"\\e90f"');
  $icon-slideshow-no-border: unquote('"\\e911"');
  $icon-custom-no-border: unquote('"\\e912"');
  $icon-quiz-no-border: unquote('"\\e913"');
  $icon-countdown-no-border: unquote('"\\e914"');
  $icon-poll-no-border: unquote('"\\e90c"');
  $icon-remove: unquote('"\\e90b"');
  $icon-custom-field: unquote('"\\e909"');
  $icon-suitcase: unquote('"\\e90a"');
  $icon-home: unquote('"\\e908"');
  $icon-phone: unquote('"\\e907"');
  $icon-envelope: unquote('"\\e906"');
  $icon-check-mark: unquote('"\\e905"');
  $icon-dropdown-chevron-down: unquote('"\\e904"');
  $icon-dropdown-chevron-up: unquote('"\\e903"');
  $icon-person: unquote('"\\e902"');
  $icon-info: unquote('"\\e901"');
  $icon-computer: unquote('"\\e900"');
  