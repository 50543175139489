@import './variables.scss';

.screenContainer{
    padding: 25px;
    position: absolute;
    top: 62px;
    width: 100%;
    box-sizing: border-box;
    @include desktop-media(
        $width: 432px,
        $padding: 41px,
        $background: #FFFFFF,
        $top: 142px,
        $left: 50%,
        $transform: translateX(-50%),
    );
    &.authFrame {
        @include desktop-media(
            $border: 1px solid #E5E5E5,
            $box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08),
            $border-radius: 4px,
        );
    }
    &.left{
        border-top-left-radius: 0;
    }
    &.right{
        border-top-right-radius: 0;
    }
}
body{
    &:not(.templates-page) {
        background: $basic-dark;
        @include desktop-media(
            $background: $basic-white,
        );
    }
    height: 100vh;
    margin: 0;
    position: relative;
}

.responsiveGridLayout {
	display: grid;
	grid-template-columns: $templates-container-horizontal-margin-desktop auto $templates-container-horizontal-margin-desktop;

    .responsiveMainContent {
        grid-column: 2;
        max-width: $templates-container-max-width-desktop;
    }

    @include large-desktop {
        grid-template-columns: 1fr $templates-container-max-width-desktop 1fr;
    }

    @include tablet {
        grid-template-columns: $templates-container-horizontal-margin-tablet auto $templates-container-horizontal-margin-tablet;
    }

    @include phone {
        grid-template-columns: $templates-container-horizontal-margin-phone auto $templates-container-horizontal-margin-phone;
    }
}

.templateGridRow {
    grid-template-columns: repeat($templates-column-amount-desktop, 1fr);
    display: grid;
	grid-gap: $templates-grid-gap;
	justify-content: center;
    grid-auto-rows: minmax(200px, auto);
	position: relative;

    @include tablet {
        grid-template-columns: repeat($templates-column-amount-tablet, 1fr);
        grid-gap: $templates-grid-gap-tablet;
    }

    @include phone {
        grid-template-columns: repeat($templates-column-amount-phone, 1fr);
    }
}


.linkContainer {
    &:first-letter {
        text-transform: capitalize;
    }
    cursor: pointer;
    @include desktop-media(
        $text-align: left,
    );
}

.link {
    font-family: $primaryFontFamily;
    font-size: 12px;
    line-height: 1.5;
    color: $link-gray;
    text-decoration-line: underline;
    font-weight: 500;
    &.noDecoration {
        text-decoration-line: none;
        @include desktop-media(
            $color: $basic-black,
        );
    }
    @include desktop-media(
        $font-weight: 500,
        $font-size: 12px,
        $line-height: 16px,
        $text-decoration-line: underline,
        $color: $basic-blue,
    );
}

.error {
    font-family: $primaryFontFamily;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $primary500;
    white-space: pre-line;
}

.disclaimer{
    font-family: $primaryFontFamily;
    font-size: 3.2vw;
    line-height: 20px;
    text-align: center;
    color: $basic-gray;
    margin-top: -8px;
    a{
        margin: 0 5px;
        color: $basic-gray;
    }
    @include desktop-media(
        $font-size: 11px,
    );
}

.apeButton{
    width: 100%;
    background: $primary500;
    border-radius: 74px;
    padding: 14px 0;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: $primaryFontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: $basic-white;
    border: none;
    cursor: pointer;
    outline: none !important;
    &:hover{
        background: $primary600;
    }

    &.disabled {
        background: $basic-gray;
    }

    .signupForm & {
        grid-row: 9;
        grid-column: 1 / 3;
    }
    .loginForm & {
        grid-row: 7;
    }
    .forgotPassword &{
        grid-row: 3;
    }
    .almostThere{
        grid-row: 1;
    }
    .recoverPassword & {
        grid-row: 5;
    }
    .verify & {
        grid-row: 4;
    }
}

.hide{
    display: none !important;
}
.slick-slider{
    height: 309px !important;
    .slick-list, .slick-track{
        height: 100%;
    }
}
.carouselDots{
    li{
        margin: 0 !important;
        button{
            &:before{
                color: $basic-gray !important;
                .slick-active &{
                    color: $basic-white !important;
                }
            }
        }
    }
}

.invisible{
    visibility: collapse;
}

.textCenter {
    text-align: center;
}

form {
    &.forgotPassword {
        grid-template-rows: auto 32px auto;
    }
    &.almostThere {
        grid-template-rows: auto;
    }

    &.recoverPassword {
        grid-template-rows: auto 16px auto 32px auto;
    }

    &.successfullyRecovered {
        margin-top: 10px;
    }

    &.verify {
        grid-template-rows: auto auto 32px auto;
    }
}

.authGeneral {
    display: grid;
    grid-template-rows: 16px auto 32px auto 42px auto;
    @include desktop-media(
        $grid-template-rows: 0px 0px 0px auto 48px auto,
    );
}

.signupForm {
    grid-template-columns: 50% 50%;
    column-gap: 10px;
    grid-template-rows: auto 16px auto 16px auto 16px auto 32px auto 12px;
    @include desktop-media(
        $grid-template-rows: auto 20px auto 20px auto 20px auto 32px auto 12px,
    );
}

.loginForm {
    grid-template-rows: auto 16px auto 4px auto 36px auto;
    @include desktop-media(
        $grid-template-rows: auto 20px auto 12px auto 36px auto,
    );
}

.authToggle{
    grid-row: 2;
    @include desktop-media(
        $grid-row: none,
    );
}

input[type=radio] {
    display: none;
}

input[type=radio] + label:before {
    box-sizing: border-box;
    height: 16px;
    width: 16px;
    display: inline-block;
    vertical-align: baseline;
    border: 2px solid #C4C4C4;
    content: " ";
}

input[type=radio]:checked + label:before {
    border: 5px solid $primary500;
    box-sizing: border-box;
}

input[type=radio] + label:before {
    border-radius:50%;
}

input[type=radio] + label:after {
    margin-bottom: 3px;
}

@each $name, $color in $engineColors {
    i.icon-#{$name} {
      color: $color;
        &.border {
            border: 2px solid #bebebe;
        }
    }
      .engine-icon-#{$name}-background:hover {
          background: transparentize($color, 0.9);
      }
      .engine-icon-#{$name} {
        border: 2px solid $color;
        color: $color;

        &.grayish {
            color: #bebebe!important;
            border: 2px solid #bebebe;
        }
        &.selected {
          border-color: $color !important;
          color: #fff!important;
        }
        &.filled {
          background: $color;
        }
      }
    button.#{$name} {
        background: transparentize($color, 0.9);
    }
}

.create-button {
    i.icon-custom {
        color: white;
    }
}

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?rew615') format('woff2'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rew615') format('truetype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rew615') format('woff'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rew615##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-auto-poll {
    &:before {
      content: $icon-auto-poll; 
    }
  }
  .icon-star {
    &:before {
      content: $icon-star; 
    }
  }
  .icon-user-profile {
    &:before {
      content: $icon-user-profile; 
    }
  }
  .icon-countdown {
    &:before {
      content: $icon-countdown; 
    }
  }
  .icon-custom {
    &:before {
      content: $icon-custom; 
    }
  }
  .icon-gallery {
    &:before {
      content: $icon-gallery; 
    }
  }
  .icon-personality {
    &:before {
      content: $icon-personality; 
    }
  }
  .icon-poll {
    &:before {
      content: $icon-poll; 
    }
  }
  .icon-quiz {
    &:before {
      content: $icon-quiz; 
    }
  }
  .icon-video {
    &:before {
      content: $icon-video; 
    }
  }
  .icon-story {
    &:before {
      content: $icon-story; 
    }
  }
  .icon-cross {
    &:before {
      content: $icon-cross; 
    }
  }
  .icon-union {
    &:before {
      content: $icon-union; 
    }
  }
  .icon-arrow-left {
    &:before {
      content: $icon-arrow-left; 
    }
  }
  .icon-arrow-right {
    &:before {
      content: $icon-arrow-right; 
    }
  }
  .icon-video-no-border {
    &:before {
      content: $icon-video-no-border; 
    }
  }
  .icon-gallery-no-border {
    &:before {
      content: $icon-gallery-no-border; 
    }
  }
  .icon-personality-no-border {
    &:before {
      content: $icon-personality-no-border; 
    }
  }
  .icon-arrow-next {
    &:before {
      content: $icon-arrow-next; 
    }
  }
  .icon-article {
    &:before {
      content: $icon-article; 
    }
  }
  .icon-story-no-border {
    &:before {
      content: $icon-story-no-border; 
    }
  }
  .icon-slideshow-no-border {
    &:before {
      content: $icon-slideshow-no-border; 
    }
  }
  .icon-custom-no-border {
    &:before {
      content: $icon-custom-no-border; 
    }
  }
  .icon-quiz-no-border {
    &:before {
      content: $icon-quiz-no-border; 
    }
  }
  .icon-countdown-no-border {
    &:before {
      content: $icon-countdown-no-border; 
    }
  }
  .icon-poll-no-border {
    &:before {
      content: $icon-poll-no-border; 
    }
  }
  .icon-remove {
    &:before {
      content: $icon-remove; 
    }
  }
  .icon-custom-field {
    &:before {
      content: $icon-custom-field; 
    }
  }
  .icon-suitcase {
    &:before {
      content: $icon-suitcase; 
    }
  }
  .icon-home {
    &:before {
      content: $icon-home; 
    }
  }
  .icon-phone {
    &:before {
      content: $icon-phone; 
    }
  }
  .icon-envelope {
    &:before {
      content: $icon-envelope; 
    }
  }
  .icon-check-mark {
    &:before {
      content: $icon-check-mark; 
    }
  }
  .icon-dropdown-chevron-down {
    &:before {
      content: $icon-dropdown-chevron-down; 
    }
  }
  .icon-dropdown-chevron-up {
    &:before {
      content: $icon-dropdown-chevron-up; 
    }
  }
  .icon-person {
    &:before {
      content: $icon-person; 
    }
  }
  .icon-info {
    &:before {
      content: $icon-info; 
    }
  }
  .icon-computer {
    &:before {
      content: $icon-computer; 
    }
  }
  